import React from 'react';
import { graphql } from 'gatsby';
import { shape, string } from 'prop-types';

import About from 'components/blocks/about';
import Layout from 'components/layout';
import OtherServices from 'components/blocks/other_services';
import PageHeader from 'components/blocks/page_header';
import PageTitle from 'components/ui/page_title';
import SEO from 'components/seo';
import AboutEds from 'components/blocks/AboutEds';

const Template = ({
  data,
}) => {
  const {
    frontmatter: {
      about, title, seoTitle, type, image, subtitle, slug, description, keyWords,
    }, html,
  } = data.markdownRemark;

  return (
    <Layout pageType={type}>
      <SEO title={seoTitle || title} description={description} keyWords={keyWords} />

      <div className="service_page">
        <PageHeader
          html={html}
          image={image}
          header={<PageTitle title={title} hideLabel subtitle={subtitle} />}
        />

        <div className="service_page__about">
          {slug === '/services/eds/' ? <AboutEds /> : <About data={about} />}
        </div>

        <div className="service_page__nav">
          <OtherServices />
        </div>
      </div>

    </Layout>
  );
};

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { slug: { eq: $path } }) {
      html
      frontmatter {
        about {
          image
          text
          title
        }
        description
        image
        keyWords,
        seoTitle
        slug
        subtitle
        title
      }
    }
  }
`;

Template.propTypes = {
  data: shape({
    markdownRemark: shape({
      frontmatter: shape({
        description: string,
        keyWords: string,
        seoTitle: string,
        title: string.isRequired,
      }),
    }).isRequired,
  }).isRequired,
};

export default Template;
