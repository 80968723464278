import React from 'react';
import { bool } from 'prop-types';

import { AboutContainer, ColumnsList } from 'components/HOC';

// Data
import data from 'data/about/eds.yaml';

// SASS
import './_about_eds.sass';

const AboutEds = ({ reverse }) => (
  <AboutContainer reverse={reverse}>
    <div className="about_eds">
      <ColumnsList data={data} />
    </div>
  </AboutContainer>
);

AboutEds.propTypes = {
  reverse: bool,
};

AboutEds.defaultProps = {
  reverse: false,
};

export default AboutEds;
