import raw from 'utils/raw';
import React from 'react';
import typograf from 'utils/typograf';
import { shape, string, arrayOf } from 'prop-types';

// SASS
import './_columns_list.sass';

const ColumnsList = ({ data }) => (
  <ul className="columns_list">
    {data.map(({ title, content }) => (
      <li className="columns_list__item" key={title}>
        <h3 className="columns_list__title">{raw(typograf(title))}</h3>
        {content && raw(typograf(content))}
      </li>
    ))}
  </ul>
);

ColumnsList.propTypes = {
  data: arrayOf(
    shape({
      content: string,
      title: string.isReauired,
    }).isRequired,
  ).isRequired,
};

export default ColumnsList;
