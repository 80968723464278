import React from 'react';
import typograf from 'utils/typograf';
import { Link } from 'gatsby';

import Container from 'components/layout/container';
import ReviewsLink from 'components/ui/reviews_link';
import ServicesNav from 'components/blocks/services_nav';

const OtherServices = () => (
  <div className="other_services other_services-masked">
    <div className="other_services__inner">
      <Container>
        <span className="other_services__top">
          <ReviewsLink />
        </span>
        <h3 className="other_services__title title title-uppercased">
          {typograf('Другие наши услуги')}
        </h3>

        <ServicesNav row />

        <div className="other_services__footer">
          <Link to="/faq/" className="link link-centered">Частые вопросы</Link>
        </div>
      </Container>
    </div>

  </div>
);

export default OtherServices;
